import React from "react"
import { Link } from "gatsby"

import Master from "../layout/_master"
import SEO from "../components/seo"

const SecondPage = () => (
  <Master>
    <SEO title="Page two" />
    <h1>Projects</h1>
    <p>Umm... this is embarrassing... If I told you I'd have to kill you, NDA</p>
    <p>Nah I'll get some projects up eventually I pinky promise</p>
    <Link to="/">Go back to the homepage</Link>
  </Master>
)

export default SecondPage
